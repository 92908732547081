import { createSelector, createSlice } from "@reduxjs/toolkit";
import { reuseProductThunks } from "./reuseProductsThunkAction";

const initialState = {
    products: {},
    status: 'idle',
    error: null
}

/* Product Slice configuration */
const reuseProductSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        resetProductAuthData: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(reuseProductThunks.fetchReuseProduct.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(reuseProductThunks.fetchReuseProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newProducts = action.payload || {};
                state.products = newProducts;
            })
            .addCase(reuseProductThunks.fetchReuseProduct.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            .addCase(reuseProductThunks.fetchMoreReuseProducts.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(reuseProductThunks.fetchMoreReuseProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newProducts = action.payload?.body;
                const newProductsData = action.payload?.body?.content || [];
                state.products.body = {...newProducts, content: [...state.products?.body?.content, ...newProductsData] };
            })
            .addCase(reuseProductThunks.fetchMoreReuseProducts.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
    }
})

export const getReuseProductsSelector = createSelector((state) => state?.products, (products) => products);
export const { resetProductAuthData } = reuseProductSlice.actions;
export default reuseProductSlice.reducer;