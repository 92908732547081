import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../config";

const fetchReuseProduct = createAsyncThunk('products/fetchProducts', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.post(`${Apis.GetGlobalReuseProducts}?page=${data?.page}&size=${data?.size}`, data);
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch products');
    }
});

const fetchMoreReuseProducts = createAsyncThunk('products/fetchMoreProducts', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.post(`${Apis.GetGlobalReuseProducts}?page=${data?.page}&size=${data?.size}`, data);;
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch more products');
    }
});


export const reuseProductThunks = { fetchReuseProduct,  fetchMoreReuseProducts};

